<template id="report-entry-item" >
  <ion-item v-if="htmlInputType === 'datetime'" ref="entryItemRef" :class="`entry-item subdivided ${statusStyle} ${platformStyle}`" :lines="lines">
    <ion-label class="wrapping-label" position="stacked">{{ display_name }}</ion-label>
    <!-- Button to clear the entered value -->
    <div v-if="hasValue" slot="start" class="reset-button" @click.stop="resetEntry" @keydown="handleSpacebar($event, ()=>$event.target.click())">
      <ion-button fill="clear" color="danger" shape="round">
        <ion-icon slot="icon-only" :icon="closeCircleOutline"></ion-icon>
      </ion-button>
    </div>
    <div class="item-container"> <!-- TODO Icon -->
      <!-- Add custom input to set a custom validity to, that gets checked, before submit. Has no other purpose -->
      <input tabindex="-1" inputmode="none" @focus.stop="cancelFocus" type="text" class="invisible-input custom-validity" ref="customValidityInput" :name="`${key}${CATEGORY_SEPARATOR}customValidity`" />
      <ion-item lines="none" @click="forwardClickToIonInput">
        <ion-label position="stacked">{{ i18n.$t('default_interaction.date') }}</ion-label>
        <ion-input 
          :class="(dateInternalModel == null || (!dateInternalModel.length)) ? 'empty-date-time' : ''"
          type="date"
          v-model="dateInternalModel"
          :key="dateModel"
          @ionBlur="validateAndSetDate($event.target.value)"
          @keydown="blurOnEnter($event)"
          :custom-placeholder="i18n.$t('default_interaction.select')"
          :max="localMaxDate"
          @click="openDateTimeInput"
          tabindex="2"></ion-input> <!-- Key attribute forces reload on change of that attribute -->
      </ion-item>
      <ion-item lines="none" @click="forwardClickToIonInput">
        <ion-label position="stacked">{{ i18n.$t('default_interaction.time') }}</ion-label> <!--TODO Maybe add all ion-inputs to a reflist and update their tabindex to 0 once the list changes -->
        <ion-input
          :class="(timeInternalModel == null || (!timeInternalModel.length)) ? 'empty-date-time' : ''"
          type="time"
          v-model="timeInternalModel"
          :key="timeModel"
          @ionBlur="validateAndSetTime($event.target.value)"
          @keydown="blurOnEnter($event)" 
          :custom-placeholder="i18n.$t('default_interaction.select')"
          :max="(dateModel == localMaxDate) ? localMaxTime : undefined"
          @click="openDateTimeInput"
          tabindex="3"></ion-input> <!-- Time constraint is only applied when we are on the max day, before that it is not necessary -->
      </ion-item>
    </div>
  </ion-item>
  <ion-item v-else ref="entryItemRef" :class="`entry-item ${statusStyle} ${platformStyle}`" :lines="lines" detail="false">
    <ion-label class="wrapping-label" :position="defaultHTMLinputTypes.includes(htmlInputType) ? 'stacked' : 'fixed'">
      {{ display_name }}
    </ion-label>
    <!-- Button to clear the entered value -->
    <div v-if="hasValue" slot="start" class="reset-button" @click.stop="resetEntry" @keydown="handleSpacebar($event, ()=>$event.target.click())">
      <ion-button fill="clear" color="danger" shape="round">
        <ion-icon slot="icon-only" :icon="closeCircleOutline"></ion-icon>
      </ion-button>
    </div>
    <!-- Add custom input to set a custom validity to, that gets checked, before submit. Has no other purpose -->
    <input tabindex="-1" inputmode="none" @focus.stop="cancelFocus" type="text" class="invisible-input custom-validity" ref="customValidityInput" :name="`${key}${CATEGORY_SEPARATOR}customValidity`" />
    <!-- Show a checkbox, if it is a boolean -->
    <div v-if="htmlInputType === 'checkbox'" class="checkbox-container" tabindex="2" @keydown="handleSpacebar($event, ()=> inputValue = !inputValue )">
      <ion-checkbox
        :name="key"
        :checked="inputValue"
        :indeterminate="inputValue === undefined"
        @ionChange="inputValue = $event.target.checked" 
        tabindex="-1">
      </ion-checkbox>
    </div>
    <!-- Show regular inputs if not a file -->
    <div v-else-if="!fileInputTypes.includes(htmlInputType)" :class="unit ? 'input-container with-unit' : 'input-container'" tabindex="-1">
      <!-- Show default input, when custom values are allowed but no preset values are set -->
      <ion-textarea v-if="htmlInputType === 'text' && allow_custom_values && (!available_values || overrideAvailableValues)" 
        rows="3"
        :name="key"
        :type="htmlInputType"
        :inputmode="specialInputMode"
        enterkeyhint="next"
        v-model="inputValue"
        class="custom-input"
        :placeholder="custom_placeholder ? custom_placeholder : i18n.$t('default_interaction.enter_text')"
        @keydown="blurOnEnter($event)" 
        tabindex="-1">
      </ion-textarea>

      <ion-input v-else-if="defaultHTMLinputTypes.includes(htmlInputType) && allow_custom_values && (!available_values || overrideAvailableValues)" 
        :name="key"
        :type="htmlInputType"
        :inputmode="specialInputMode"
        :step="specialInputStep"
        enterkeyhint="next"
        v-model="inputValue"
        class="custom-input"
        :placeholder="custom_placeholder ? custom_placeholder : 
          i18n.$t((htmlInputType != 'number') ? 'default_interaction.enter_text' : 'default_interaction.enter_value')" 
        tabindex="2">
      </ion-input>

      <!-- Wrapped in an div to prevent overlapping other elements and to group it as a custom button -->
      <div class="select-container" v-if="defaultHTMLinputTypes.includes(htmlInputType) && available_values" @click.stop="openSelect(true)" tabindex="3" @keydown="handleSpacebar($event, openSelect)">
        <!-- Show select input when values for a selection are given. Hide everything except an icon if it is overriden by a custom input -->
        <ion-select
          ref="selectInput"
          :class="overrideAvailableValues ? 'hidden-select-content' : ''"
          :placeholder="custom_placeholder ? custom_placeholder : i18n.$t('default_interaction.select')"
          :cancelText="i18n.$t('default_interaction.cancel')"
          :okText="i18n.$t('default_interaction.select')"
          :interface-options="{cssClass: 'select-entry-item'}"
          :multiple="allow_multiple_values"
          v-model="inputValueForSelect"
          tabindex="-1">
          <ion-select-option v-for="(value, valueIndex) in available_values" :key="valueIndex" :value="value.value">{{ value.display }}</ion-select-option>
          <!-- Add a option to add custom values, if it is allowed and not already enabled -->
          <ion-select-option v-if="allow_custom_values && !overrideAvailableValues" class="custom-value" :value="overrideAvailableValuesSelectName">{{ i18n.$t('report.create.custom_value') }}</ion-select-option>
        </ion-select>
        <div v-if="overrideAvailableValues" class="select-open-icon"><div class="select-open-icon-inner"></div></div>
      </div>
      <ion-item v-else-if="htmlInputType === 'date'" lines="none" @click="forwardClickToIonInput">
        <ion-input
          :class="(dateInternalModel == null || (!dateInternalModel.length)) ? 'empty-date-time' : ''"
          type="date"
          v-model="dateInternalModel"
          :key="dateModel"
          @ionBlur="validateAndSetDate($event.target.value)"
          @keydown="blurOnEnter($event)" 
          :custom-placeholder="i18n.$t('default_interaction.select')"
          :max="localMaxDate"
          @click="openDateTimeInput"
          tabindex="2"></ion-input>
      </ion-item>
      <ion-item v-else-if="htmlInputType === 'time'" lines="none" @click="forwardClickToIonInput">
        <ion-input
          :class="(timeInternalModel == null || (!timeInternalModel.length)) ? 'empty-date-time' : ''"
          type="time"
          v-model="timeInternalModel"
          :key="timeModel"
          @ionBlur="validateAndSetTime($event.target.value)"
          @keydown="blurOnEnter($event)" 
          :custom-placeholder="i18n.$t('default_interaction.select')"
          :max="localMaxTime"
          @click="openDateTimeInput"
          tabindex="2"></ion-input>
      </ion-item>
      
      <span class="unit" v-if="unit">{{ unit }}</span>
    </div>
    <!-- Show file inputs if a file -->
    <div v-else slot="end" class="input-buttons">
      <ion-badge slot="end" color="primary">{{ (selectedFileCounts.preset > 0) ? selectedFileCounts.preset : null }}</ion-badge>
      <ion-badge slot="end" color="success">{{ (selectedFileCounts.modified > 0) ? selectedFileCounts.modified : null }}</ion-badge>

      <input tabindex="-1" class="invisible-input" ref="fileInput" @change="editSelectedFiles($event.target.files)" v-if="htmlInputType === 'image'" :name="key" type="file" :accept="(capture_options['still_frame']) ? 'image/*,video/*' : 'image/*'" :multiple="multipleFiles" capture />
      <input tabindex="-1" class="invisible-input" ref="fileInput" @change="editSelectedFiles($event.target.files)" v-else-if="htmlInputType === 'video'" :name="key" type="file" accept="video/*" :multiple="multipleFiles" capture />
      <input tabindex="-1" class="invisible-input" ref="fileInput" @change="addSelectedFiles($event.target.files)" v-else-if="htmlInputType === 'audio'" :name="key" type="file" accept="audio/*" :multiple="multipleFiles" capture />

      <!-- TODO Add popup gallery to delete files selectively -->

      <ion-button @click.stop="captureFile" @keydown="handleSpacebar($event, ()=>$event.target.click())">
        <ion-icon slot="icon-only" :icon="fileCaptureIcon"></ion-icon>
      </ion-button>
      <ion-button @click.stop="chooseFile" @keydown="handleSpacebar($event, ()=>$event.target.click())">
        <ion-icon slot="icon-only" :icon="folderOpen"></ion-icon>
      </ion-button>
    </div>
  </ion-item>
</template>



<script>

import { IonItem, IonLabel, IonTextarea, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon, IonCheckbox, IonBadge, isPlatform } from '@ionic/vue';
import { defineComponent, computed, ref, watch, onMounted } from 'vue';

import { camera, videocam, mic, folderOpen, closeCircleOutline } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { useDayjs } from '@/utils/dayjs';

import { openCameraModal, default as cameraModalComponent } from '@/components/CameraModal.vue';

import _ from 'lodash';

export default defineComponent({
  name: 'ReportEntryItem',
  components: { IonItem, IonLabel, IonTextarea, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon, IonCheckbox, IonBadge },
  props: {
    'lines': {
      type: String,
      default: 'inset'
    },
    'category': String,
    'sub_category': String,
    'name': String,
    'display_name': String,
    'type': String,
    'pro_only': Boolean, //TODO Hide if not a veterenarian and pro only is set
    'allow_custom_values': Boolean,
    'allow_multiple_values': Boolean,
    'unit': String,
    'available_values': Array,
    'preset_value': {
      type: [String, Number, Boolean, Object],
      default: undefined
    },
    'custom_placeholder': String,
    'required': Boolean,
    'max': [String, Number], //TODO Maybe use for other inputs than date too
    'capture_options': {
      type: Object,
      default: () => {
        return {};
      }
    },
    //TODO Add option for additional constraints for regular inputs like text or number (e.g. maxlength). They should be checked automatically by the validation routine!
  },
  setup(props) {
    const i18n = useI18n();

    const entryItemRef = ref(null);

    const { dayjs, timezone } = useDayjs();
    
    const defaultHTMLinputTypes = ["email", "number", "password", "search", "tel", "text", "url"];

    const dateTimeInputTypes = ["datetime", "date", "time"];

    const fileInputTypes = ["image", "video", "audio"];

    const cameraInputTypes = ["image", "video"];

    const preset_value_abstraction = computed(() => props.preset_value);

    const preset_files = ref([]);

    const CATEGORY_SEPARATOR = '->';

    const SELECT_ARRAY_SEPARATOR = '; ';

    //Set when a custom input is allowed and selected from the list
    const overrideAvailableValues = ref(false);

    const DATE_FORMAT = 'YYYY-MM-DD';

    const TIME_FORMAT = 'HH:mm';

    const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

    const dateInternalModel = ref('');
    const dateModel = ref('');

    //Use a separate model that gets updated on each input not just on blur
    watch(dateModel, (newDate) => {
      dateInternalModel.value = newDate;
    })

    const timeInternalModel = ref('');
    const timeModel = ref('');

    //Use a separate model that gets updated on each input not just on blur
    watch(timeModel, (newTime) => {
      timeInternalModel.value = newTime;
    })

    const localMaxDateTime = computed(() => {
      if (props.max != null) {
        let maxValue = processValueTypes(props.max);
        if (maxValue != null) {
          let newDateTime = dayjs.utc(maxValue);
          if (newDateTime != null && newDateTime.isValid()) {
            return newDateTime.local().tz(timezone);
          }
        }
      }
      return undefined;
    });

    const localMaxDate = computed(() => {
      if (localMaxDateTime.value != null)
        return localMaxDateTime.value.format(DATE_FORMAT);
      return undefined;
    });

    const localMaxTime = computed(() => {
      if (localMaxDateTime.value != null)
        return localMaxDateTime.value.format(TIME_FORMAT);
      return undefined;
    });

    const platformStyle = computed(() => {
      if (isPlatform('android')) return 'android';
      else return '';
    });

    const processValueTypes = function(value){
      let newPresetValue;
      if (value != null){
        switch (props.type) {
          case 'number':
            newPresetValue = parseInt(value);
            break;
          case 'decimal':
            newPresetValue = parseFloat(value);
            break;
          //Remove unneeded precision of all date/time values
          case 'date':
          case 'time':
          case 'datetime': {
            let newDateTime = dayjs.utc(value);
            if (newDateTime != null && newDateTime.isValid())
              newPresetValue = dayjs.utc(newDateTime.format(`${DATE_FORMAT} ${TIME_FORMAT}`), `${DATE_FORMAT} ${TIME_FORMAT}`).toISOString();
            break;
          }
          default:
            newPresetValue = value;
            break;
        }
      } else {
        newPresetValue = value;
      }
      return newPresetValue;
    }

    const htmlInputType = computed(() => {
      //Handle default cases
      if (defaultHTMLinputTypes.includes(props.type) || dateTimeInputTypes.includes(props.type) || fileInputTypes.includes(props.type)){
        return props.type;
      }
      //Handle special cases
      switch (props.type) {
        case 'decimal':
          return 'number';
        case 'bool':
          return 'checkbox';
        //Handle pluralized file types
        case 'images':
          return 'image';
        case 'videos':
          return 'video';
        case 'audios':
          return 'audio';
        //Fallback to text
        default:
          return 'text';
      }//TODO Add multiline textareas, where newlines don't get trimmed?
    });

    const multipleFiles = computed(() => {
      //Handle pluralized file types
      switch (props.type) {
        case 'images':
          return true;
        case 'videos':
          return true;
        case 'audios':
          return true;
        default:
          return false;
      }
    });

    const isFile = computed(() => { //TODO Check file type locally, if it is a valid type, before sending to to the server
      if (fileInputTypes.includes(htmlInputType.value)){
        return true;
      } else {
        return false;
      }
    });

    const selectedFiles = ref(null);

    const addSelectedFiles = function(fileList){
      if (multipleFiles.value && Array.isArray(selectedFiles.value)){
        selectedFiles.value = Array.from(selectedFiles.value).concat(Array.from(fileList)); //TODO Calculate the hash of each file and only add each file once
      } else {
        selectedFiles.value = Array.from(fileList);
      }
      fileInput.value.value = '';
    }

    const processPresetValueWithSideEffects = function(value) {
      let newPresetValue = processValueTypes(value);

      if (dateTimeInputTypes.includes(props.type) && newPresetValue != null){
        let newDateTime = dayjs.utc(newPresetValue);
        if (newDateTime != null && newDateTime.isValid()) {
          newDateTime = newDateTime.local().tz(timezone);

          let newDateString = newDateTime.format(DATE_FORMAT);
          if (newDateString != dateModel.value) {
            dateModel.value = newDateString;
          }
          let newTimeString = newDateTime.format(TIME_FORMAT);
          if (newTimeString != timeModel.value) {
            timeModel.value = newTimeString;
          }
        } else {
          dateModel.value = '';
          timeModel.value = '';
          newPresetValue = null;
        }
      }

      //If the preset value is not present in the available values, if they are supplied, we assume a custom value - Only apply when newPresetValue is set
      if (props.available_values && newPresetValue != null && !(_.map(props.available_values, 'value').includes(newPresetValue))){
        overrideAvailableValues.value = true;
      }

      //If we have a file set the preset_files in a separate array to apply them when necessary
      if (isFile.value && newPresetValue != null){
        let filePromises = [];
        if (!Array.isArray(newPresetValue)) newPresetValue = [newPresetValue]; //Always use an array of files for easier handling
        for (let fileObject of newPresetValue){
          if (fileObject.blobURL && fileObject.name) { //It is a file if blobURL argument is set
            filePromises.push(
              fetch(fileObject.blobURL)
                .then(fetchResult => fetchResult.blob())
                .then(blobFile => new File([blobFile], fileObject.name, { type: fileObject.mime || blobFile.type })) //Use the given mimeType or read it from the blob
                .catch((error) => {
                  console.error(error);
                  return null;
                })
            );
          }
        }

        Promise.all(filePromises).then((fileArray) => {
          preset_files.value = fileArray;
          selectedFiles.value = fileArray;
        });
      }

      return newPresetValue;
    }

    const inputValueRef = ref(processPresetValueWithSideEffects(preset_value_abstraction.value)); //Holds the preset_value initially, can also be an array in case of multi-select

    watch(() => props.preset_value, (newPreset) => {
      inputValueRef.value = processPresetValueWithSideEffects(newPreset);
    });

    //Convert all available values to easily find them for translation when displaying them in a custom field
    const indexedAvailableValues = computed(() => {
      let index = {
        byValue: {},
        byDisplay: {}
      }

      if (props.available_values){
        for (let availableValue of Object.values(props.available_values)){
          index.byValue[availableValue.value] = availableValue.display;
          index.byDisplay[availableValue.display] = availableValue.value;
        }
      }

      return index;
    })

    const convertAvailableValueToDisplay = function(availableValue){
      if (indexedAvailableValues.value.byValue[availableValue]) {
        return indexedAvailableValues.value.byValue[availableValue];
      } else {
        return availableValue;
      }
    }

    const convertDisplayValueToAvailable = function(displayValue){
      if (indexedAvailableValues.value.byDisplay[displayValue]) {
        return indexedAvailableValues.value.byDisplay[displayValue];
      } else {
        return displayValue;
      }
    }

    const inputValue = computed({
      get: () => { //Convert array to separated string
        if (inputValueRef.value && Array.isArray(inputValueRef.value)) {
          let displayArray = [];
          for (let value of inputValueRef.value){
            displayArray.push(convertAvailableValueToDisplay(value));
          }
          return displayArray.join(SELECT_ARRAY_SEPARATOR);
        }
        return convertAvailableValueToDisplay(inputValueRef.value);
      },
      set: (newValue) => { //Convert separated string to array
        //Set the value in a computed property to accommodate for an issue 
        //where ion-input sets an empty string that is somehow an undefined string when it should be of type undefined
        //False has to be checked separately, as it is a valid value
        if (!newValue && newValue !== false){
          inputValueRef.value = undefined;
          return;
        }

        if(newValue && newValue.split){ //Only try to split, if this function exists, aka is a string
          let valueArray = newValue.split(SELECT_ARRAY_SEPARATOR);
          if (valueArray.length === 1) { //Set only the value if it is a single one
            inputValueRef.value = convertDisplayValueToAvailable(valueArray[0]);
          } else {
            let convertedArray = [];
            for (let value of valueArray){
              convertedArray.push(convertDisplayValueToAvailable(value));
            }
            inputValueRef.value = convertedArray;
          }
        } else {
          inputValueRef.value = convertDisplayValueToAvailable(newValue);
        }
      }
    });

    const mapAvailableValuesToValuesOnly = function(availableValue){
      return availableValue.value;
    }

    //Custom select element that is just used to trigger setting a flag
    const overrideAvailableValuesSelectName = 'enable_custom_value';

    //Abstract the input value for select to handle all values including custom values while being able to display them in a normal input using a separator
    const inputValueForSelect = computed({
      get: () => {
        return inputValueRef.value;
      },
      set: (newValue) => {
        //Set the value in a computed property to accommodate for an issue 
        //where ion-select sets an empty string that is somehow an undefined string when it should be of type undefined
        //False has to be checked separately, as it is a valid value
        if (!newValue && newValue !== false){
          inputValueRef.value = undefined;
          return;
        }

        checkEnableCustomValueOverride(newValue);

        if (newValue && Array.isArray(newValue)){
          //Merge existing and new list to keep custom values
          //First make a list of all currently entered values, without the available values in the select
          let customValues = [];
          let inputValues = inputValueRef.value;
          if (inputValueRef.value) {
            if (!Array.isArray(inputValues)) { //Make it an array for the next step, if it is just a value
              inputValues = [inputValues];
            }
            for (let value of inputValues){
              if (!props.available_values || !Object.values(props.available_values).map(mapAvailableValuesToValuesOnly).includes(value)){ //Only add it, if it is not in the available_values
                customValues.push(value);
              }
            }
          }

          _.pull(newValue, overrideAvailableValuesSelectName); //Remove custom value select element, because it is no longer needed!

          let customArray = [...new Set(newValue.concat(customValues))]; //Add all custom values to the selected ones and create an array with no duplicates!

          if (!_.isEqual(inputValueRef.value, customArray)) { //Only update if changed, or else we will loop indefinitely, since we mutate a dependancy
            if (customArray.length === 1) { //Set only the value if it is a single one
              inputValueRef.value = customArray[0];
            } else {
              inputValueRef.value = customArray;
            }
          }
        } else {
          if (newValue !== overrideAvailableValuesSelectName) { //Only set the value if it is not the overrideAvailableValuesSelectName, because it is just needed to set a flag
            inputValueRef.value = newValue;
          }
        }
      }
    });

    //Called when checking if the item for custom inputs was selected
    const checkEnableCustomValueOverride = function(value){
      //String value in case of single select or Array value in case of multi select
      if (value === overrideAvailableValuesSelectName || (Array.isArray(value) && value.includes(overrideAvailableValuesSelectName))) {
        overrideAvailableValues.value = true;
      } 
    }

    //Set everything to be empty!
    const resetEntry = function(){
      inputValueRef.value = undefined;
      overrideAvailableValues.value = false;
      selectedFiles.value = null;
      dateModel.value = '';
      timeModel.value = '';
      if (fileInput.value) fileInput.value.value = null;
    }

    const fileInput = ref(null);

    const selectInput = ref(null);

    const handleSpacebar = function(event, handler){
      if (event.key === ' ') {
        event.preventDefault();
        handler();
      }
    }

    const openSelect = function(openOnlyIfOverridden = false){
      if (selectInput.value && (!openOnlyIfOverridden || overrideAvailableValues.value)){
        //Remove focus from any input element to not open the keyboard accidentally
        document.activeElement.blur();
        selectInput.value.$el.open();
      }
    }

    const key = computed(() => {
      let key = props.category;
      if (props.sub_category){
        key += CATEGORY_SEPARATOR + props.sub_category;
      }
      key += CATEGORY_SEPARATOR + props.name;
      return key;
    });

    const specialInputMode = computed(() => {
      switch (props.type) {
        case 'decimal':
          return 'decimal';
        case 'number':
          return 'numeric';
        default:
          return undefined;
      }
    });

    const specialInputStep = computed(() => {
      switch (props.type) {
        case 'decimal':
          return '.001';
        default:
          return undefined;
      }
    });

    const apiComponentType = computed(() => {
      switch (props.type) {
        //Handle special cases
        case 'datetime':
          return 'date-time-val';
        case 'date':
          return 'date-val';
        case 'time':
          return 'time-val';
        //Handle pluralized file types
        case 'images':
          return 'image';
        case 'videos':
          return 'video';
        case 'audios':
          return 'audio';
        default:
          return props.type;
      }
    });

    const fileCaptureIcon = computed(() => {
      switch (htmlInputType.value) {
        case 'image':
          return camera;
        case 'video':
          return videocam;
        case 'audio':
          return mic;
        default:
          return folderOpen;
      }
    });

    const statusStyle = computed(() => {
      if (isInvalid.value) {
        return 'invalid';
      } else if (isModified.value) {
        return 'modified';
      } else if (isInPresetState.value) {
        return 'preset';
      } else {
        return '';
      }
    });

    //Remove unnecessary whitespaces in the string //FIXME Does not update the displayed string in the input
    watch(inputValueRef, () => {
      if (inputValueRef.value && inputValueRef.value.trim){
        inputValueRef.value = inputValueRef.value.trim();
      }
    })

    const parseInTimezone = computed(() => {
      return function(dateString, format) {
        let newDateTime = dayjs(dateString, format, true);
        //Check for validity
        if (newDateTime.isValid()) {
          return dayjs.tz(newDateTime.format(ISO_FORMAT), ISO_FORMAT, timezone); //Workaround to format to not have a dayjs with broken timezone state
        } else {
          return null;
        }
      }
    });

    const entryDateTimeUTC = computed(() => {
      let isDateValid = (dateModel.value != null && dateModel.value.length);
      let isTimeValid = (timeModel.value != null && timeModel.value.length);
      //Only if we can create a valid time. If datetime is requested, both need to be supplied.
      if ((props.type === 'datetime' && isDateValid && isTimeValid) || (props.type !== 'datetime' && (isDateValid || isTimeValid))) {
        //Create a string of the components, that also might be only one part, so trim all access spaces
        let combinedString = `${dateModel.value} ${timeModel.value}`;
        let newDateTime = parseInTimezone.value(combinedString.trim(), [`${DATE_FORMAT} ${TIME_FORMAT}`, DATE_FORMAT, TIME_FORMAT]);
        if (newDateTime != null) return newDateTime.utc();
      }
      return null;
    });

    //Set value that is used in the components, if the type of the input is any of datetimes
    watch(entryDateTimeUTC, (newTime) => {
      if (dateTimeInputTypes.includes(htmlInputType.value)){
        if (newTime != null && newTime.isValid())
          if (localMaxDateTime.value != null && !newTime.isSameOrBefore(localMaxDateTime.value.utc())) { //If we have a max datetime constraint and we are after that, reset to max //TODO Might not work with time or date separately
            let newDateString = localMaxDateTime.value.format(DATE_FORMAT);
            if (newDateString != dateModel.value) {
              dateModel.value = newDateString;
            }
            let newTimeString = localMaxDateTime.value.format(TIME_FORMAT);
            if (newTimeString != timeModel.value) {
              timeModel.value = newTimeString;
            }

            inputValueRef.value = localMaxDateTime.value.utc().toISOString();
            setTimeout(() => {
              checkValidity();
            }, 100); //Workaround for iOS Safari to update validity after correcting the input
          } else {
            inputValueRef.value = newTime.toISOString();
          }
        else
          inputValueRef.value = undefined;
      }
    });

    //Validate date by parsing it and then re-setting it
    const validateAndSetDate = function(date){
      let newDate = parseInTimezone.value(date, DATE_FORMAT);
      if (newDate != null) {
        let newDateString = newDate.format(DATE_FORMAT);
        if (newDateString != dateModel.value) {
          dateModel.value = newDateString;
        }
      } else {
        dateModel.value = '';
      }
    }

    //Validate time by parsing it and then re-setting it
    const validateAndSetTime = function(time){
      let newTime = parseInTimezone.value(time, TIME_FORMAT);
      if (newTime != null) {
        let newTimeString = newTime.format(TIME_FORMAT);
        if (newTimeString != timeModel.value) {
          timeModel.value = newTimeString;
        }
      } else {
        timeModel.value = '';
      }
    }

    const areFilesSelected = computed(() => {
      return (selectedFiles.value && selectedFiles.value.length > 0);
    });

    const selectedFileCounts = computed(() => {
      let fileCounts = {
        preset: 0,
        modified: 0
      }
      if (selectedFiles.value){
        let modifiedFiles = _.difference(selectedFiles.value, preset_files.value);
        fileCounts.modified = modifiedFiles.length;
        fileCounts.preset = selectedFiles.value.length - modifiedFiles.length;
      }
      
      return fileCounts;
    });

    const areFileListsEqual = computed(() => {
      if ((preset_files.value == null && selectedFiles.value.length == 0) || 
          (selectedFiles.value == null && preset_files.value.length == 0)) { //Also allow one to be an empty list and the other one to be null to be counted as equal
        return true;
      }
      if (_.isEqual(preset_files.value, selectedFiles.value)) {
        return true;
      }
      return false;
    });

    const hasValidPreset = computed(() => {
      return props.preset_value !== undefined;
    });

    const isInPresetState = computed(() => {
      if (!isFile.value && inputValueRef.value != null && processValueTypes(inputValueRef.value) === processValueTypes(props.preset_value)){
        return true;
      } else if (isFile.value && areFilesSelected.value && areFileListsEqual.value) {
        return true;
      } else {
        return false;
      }
    });

    const hasValue = computed(() => {
      if (!isFile.value && inputValueRef.value != null){
        return true;
      } else if (isFile.value && areFilesSelected.value) {
        return true;
      } else {
        return false;
      }
    });

    const isModified = computed(() => {
      if (!isFile.value && processValueTypes(inputValueRef.value) !== processValueTypes(props.preset_value)){
        return true;
      } else if (isFile.value && !areFileListsEqual.value) {
        return true;
      } else {
        return false;
      }
    });

    const isRequiredAttributeSatisfied = computed(() => {
      return !props.required || inputValueRef.value !== undefined || areFilesSelected.value;
    });

    const customValidityInput = ref(null);

    const setValidity = function(validityMessage){
      if (!validityMessage) {
        customValidityInput.value.setCustomValidity('');
      } else {
        customValidityInput.value.setCustomValidity(validityMessage);
      }
    }

    const isInvalid = ref(false);

    const checkValidity = function(){
      setValidity(null); //Set custom validity to null before check so it does not trigger invalidity
      if (entryItemRef.value){
        for (let input of entryItemRef.value.$el.getElementsByTagName('input')){
          if (!input.checkValidity()){
            isInvalid.value = true;
            setValidity(input.validationMessage); //TODO Could be fetched from "validity" manually with i18n to not use user agent language
            return true; 
          }
        }
      }
      if (!isRequiredAttributeSatisfied.value){
        isInvalid.value = true;
        setValidity(i18n.$t('default_interaction.input_required'));
        return true; 
      }
      isInvalid.value = false;
      return false; 
    }

    const reportValidity = function(){
      if (!isFile.value && entryItemRef.value) { //On file inputs the validity cannot be reported on change
        for (let input of entryItemRef.value.$el.getElementsByTagName('input')){
          try {
            input.reportValidity();
          } catch {
            return false;
          }
        }
      }
    }

    /* Check and set the invalid flag, everytime the inputValue or the required attribute as a result of this changed */
    watch([inputValueRef, isRequiredAttributeSatisfied], ([newValue, newRequired], [oldValue, oldRequired]) => {
      if (!_.isEqual(newValue, oldValue) || !_.isEqual(newRequired, oldRequired)){ //Only fire on change for performance reasons
        checkValidity();
      }
    });

    /* On every change, try to report the validity */
    watch(inputValueRef, (newValue, oldValue) => {
      if (!_.isEqual(newValue, oldValue)){ //Only fire on change for performance reasons
        reportValidity();
      }
    });

    const getEntryComponents = function() {
      let component = {
        '__component': 'report-fields.' + apiComponentType.value,
        'key': key.value
      }

      let files = null;
      let empty = false;

      if (areFilesSelected.value) {
        files = selectedFiles.value;
      } else if (!isFile.value && inputValueRef.value !== undefined) {
        if (Array.isArray(inputValueRef.value)){
          component['value'] = inputValueRef.value.join(SELECT_ARRAY_SEPARATOR); //If multiple selects are possible, the value is an array
        } else {
          component['value'] = inputValueRef.value;
        }
      } else {
        empty = true;
      }

      return { component, files, empty }; 
    }

    const getRawValue = function() {
      return inputValueRef.value;
    }

    const openCamera = function(selectedFile) {
      openCameraModal(cameraModalComponent, {
        requiresVideo: htmlInputType.value === 'video',
        stillFrame: (htmlInputType.value === 'image') ? (props.capture_options['still_frame'] || undefined) : undefined,
        captureType: props.capture_options['capture_type'] || undefined,
        flashSuggested: props.capture_options['flash_suggested'] || undefined,
        aspectRatio: props.capture_options['aspect_ratio'] || undefined,
        selectedFile
      }).then((capturedBlobData) => {
        if (capturedBlobData.data != null) {
          let filePromises = [];
          let capturedBlobs;
          if (Array.isArray(capturedBlobData.data)) {
            capturedBlobs = capturedBlobData.data;
          } else {
            capturedBlobs = [capturedBlobData.data];
          }

          for (let blob of capturedBlobs) {
            filePromises.push(
              fetch(blob.url)
                .then(fetchResult => fetchResult.blob())
                .then(blobFile => new File([blobFile], key.value || 'capturedFile', { type: blob.type || blobFile.type })) //Use the given mimeType or read it from the blob
                .catch((error) => {
                  console.error(error);
                  return null;
                })
            );
          }
          
          Promise.all(filePromises).then((fileArray) => {
            addSelectedFiles(_.filter(fileArray, (file) => file != null));
          });
        }
      });
    }

    const editSelectedFiles = function(fileList){
      if (fileList.length > 0) openCamera(fileList[0]);
      fileInput.value.value = '';
    }

    const chooseFile = function(){
      fileInput.value.removeAttribute('capture');
      fileInput.value.click();
    }

    const captureFile = function(){
      if (cameraInputTypes.includes(htmlInputType.value)) {
        openCamera();
      } else {
        fileInput.value.setAttribute('capture', true);
        fileInput.value.click();
      }
    }

    const blurOnEnter = function(event){
      //If enter key was pressed, blur
      if(event.key === 'Enter') {
        event.preventDefault();
        event.target.blur();
      }
    }

    //Used on elements with hidden elements with tabindex -1
    const cancelFocus = function(event){
      event.preventDefault();
      event.target.blur();
    }

    //Workaround for not opening the input on clicking in it the second time! E.g. on Chrome Android
    const forwardClickToIonInput = function(event) {
      let inputs = event.target.getElementsByTagName('ion-input');
      if (inputs.length > 0 && inputs[0].click) setTimeout(() => inputs[0].click(), 0);
    }

    //Workaround for custom placeholder that grabs the clicks and doesn't forward to native input! E.g. on Chrome Android
    const openDateTimeInput = function(event) {
      if (event.target.getInputElement) event.target.getInputElement().then((element) => {
        if (element != null) {
          if (element.click) element.click();
        }
      });
    }

    /* Validate and set result on mount to show initially missing fields */
    onMounted(() => {
      checkValidity();
    });

    return { 
      i18n,
      entryItemRef,
      platformStyle,
      defaultHTMLinputTypes,
      inputValue,
      inputValueForSelect,
      overrideAvailableValuesSelectName,
      overrideAvailableValues,
      resetEntry,
      fileInput,
      selectedFiles,
      addSelectedFiles,
      CATEGORY_SEPARATOR,
      key,
      statusStyle,
      htmlInputType,
      fileInputTypes,
      specialInputMode,
      specialInputStep,
      multipleFiles,
      areFilesSelected,
      selectedFileCounts,
      customValidityInput,
      hasValidPreset,
      isInPresetState,
      hasValue,
      isModified,
      isInvalid,
      getEntryComponents,
      getRawValue,
      dateInternalModel,
      dateModel,
      timeInternalModel,
      timeModel,
      localMaxDate,
      localMaxTime,
      validateAndSetDate,
      validateAndSetTime,
      openDateTimeInput,
      forwardClickToIonInput,
      selectInput,
      handleSpacebar,
      openSelect,
      editSelectedFiles,
      chooseFile,
      captureFile,
      blurOnEnter,
      cancelFocus,
      fileCaptureIcon,
      camera,
      videocam,
      mic,
      folderOpen,
      closeCircleOutline
    };
  }
});
</script>

<style>
/* Allow for longer texts in select and indent every line but the first using a negative offset on the first line in a wrapped text */
.select-entry-item .alert-radio-label, .select-entry-item .alert-checkbox-label {
  margin-left: 6px;
  white-space: normal!important;
  text-indent: -6px;
}

/* Fix that the radio buttons are displayed, even when wrapping more than two lines */
.select-entry-item .select-interface-option {
  height: auto;
  contain: content;
}

/* Hide custom value with a light color */
.select-entry-item .custom-value .alert-radio-label, .select-entry-item .custom-value .alert-checkbox-label {
  opacity: 0.5;
  font-style: italic;
}
</style>

<style scoped>
.entry-item { /* TODO Fix loading performance and interaction performance. Maybe because of many watchers, refs and computed properties. Check which properties get called. */
  display: flex;
  align-items: center;
}

.input-container {
  display: flex;
  width: 100%;
}

.unit {
  vertical-align: middle;
  padding: 8px;
  color: var(--ion-color-tertiary-tint);
  font-weight: bold;
}

.with-unit .custom-input {
  flex-shrink: 1;
}

.custom-input {
  flex-grow: 99;
  flex-basis: 0px;
}

.custom-input::part(placeholder) {
  flex-grow: 99;
  flex-basis: 0px;
}

.wrapping-label {
  margin-bottom: 8px;
  white-space: normal!important;
}

.wrapping-label.label-fixed {
  flex: 1 1 0%;
  width: 100%;
  max-width: initial;
}

.invalid > ion-label {
  color: var(--ion-color-danger)!important;
}

.modified > ion-label {
  color: var(--ion-color-success-shade)!important;
}

.preset > ion-label {
  color: var(--ion-color-primary)!important;
}

.subdivided .item-container{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.subdivided .item-container > *:not(:first-of-type) {
  border-left: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
}

/* Hide select, if both select and input are usable, to disable its button */
.hidden-select-content {
  visibility: hidden;
}
.hidden-select-content::part(placeholder), .hidden-select-content::part(text), .hidden-select-content::part(icon) {
  display: none;
}

.select-container {
  flex-grow: 1;
  display: flex;
  height: 100%;
  align-items: baseline;
  justify-content: flex-end;
}

.select-container:focus, .checkbox-container:focus {
  outline: none;
}

ion-select {
  flex-grow: 1;
  max-width: 100%;
  --padding-start: 0px;
}

/* Allow wrap of select text */
ion-select::part(text) {
  white-space: normal;
}

.select-open-icon {
  width: 19px;
  height: 19px;
  opacity: 0.33;
  padding: 9px;
  margin: 13px 0px 10px 16px; /* FIXME in iOS mode not aligned anymore */  /* TODO Move icon to bottom? */
  position: relative;
  flex-grow: 0;
  box-sizing: border-box;
}

.select-open-icon-inner {
  left: 5px;
  top: 50%;
  margin-top: -2px;
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  color: currentcolor;
  pointer-events: none;
}

.invisible-input {
  /* Avoid reflow of other elements */
  position: absolute;
  /* Show any invalidity popups in the center of the element */
  left: 50%;
  top: 50%;
  /* Hide any possibly visible parts of the input */
  background: var(--ion-background-color, white);
  border: none;
  opacity: 0;
  z-index: -10;
  /* Prevent user clicks on element, that would open the dialog twice */
  pointer-events: none;
  /* Make the element as small as possible, so it still can show the validity popup */
  width: 1px;
  height: 1px;
}

.input-buttons {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-flex;
  margin: 0px;
  align-items: center;
}

.input-buttons > ion-button {
  height: 46px;
  max-height: 46px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.input-buttons > *:not(:first-child) {
  margin-left: 16px;
}

ion-badge {
  height: 1.5em;
}

.reset-button {
  margin: 0;
  margin-right: 5px;
}

.reset-button > ion-button {
  height: auto;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --padding-start: 5px;
  --padding-end: 5px;
}

.ios .empty-date-time::before, .android .empty-date-time::before {
  padding: 8px;
  padding-bottom: 8px;
  opacity: 0.5;
  content: attr(custom-placeholder);
  pointer-events: none;
}
</style>
