<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="(loadedReport != null) ? i18n.$t('report.update.title') : i18n.$t('report.create.title')" />
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-label id="report-title">{{ localizedReportTypeName }}</ion-label>
      <form ref="formRef">
        <!-- Header for fixed inputs in all reports -->
        <ion-list>
          <!-- TODO Add icons to header inputs and add option to add a color to a horse that is shown in the icon for easier recognizability --> 
          <ReportEntryItem
            ref="reportDateTime"
            lines="full"
            name="examination_time"
            :display_name="i18n.$t('report.create.examination_time')"
            type="datetime"
            :preset_value="(loadedReport != null) ? getLoadedReportProperty('timestamp') : now"
            :max="tomorrow"
            required >
          </ReportEntryItem>
          <ion-item lines="full"> <!--TODO Make focusable via tab in Safari -->
            <ion-label position="stacked">{{ i18n.$t('report.associated_horse') }}</ion-label> <!-- TODO Preselect horse if only one horse is in the app. MAybe check this on start. -->
            <ion-select 
              :placeholder="i18n.$t('default_interaction.select')" 
              interface="action-sheet" 
              v-model="selectedHorse"
              ref="selectHorseInput" 
              :interface-options="{cssClass: 'select-horse'}"
              :cancel-text="i18n.$t('default_interaction.cancel')"
              @click="openCustomSelectInterface"
              mode="ios" >
              <ion-select-option v-for="(horse, horseId) in availableHorses" :key="horseId" :value="horseId">{{ horse.personal_horse_info.name }}</ion-select-option>
              <ion-select-option class="new-button" unfiltered bold color="success" value="new">{{ i18n.$t('report.create.new_horse') }}</ion-select-option>
            </ion-select>
          </ion-item>
          <CreateHorsePopup ref="createHorsePopup"></CreateHorsePopup>

          <ReportEntryItem
            ref="reportLocation"
            lines="full"
            name="location"
            :display_name="i18n.$t('report.location.name')"
            type="text"
            :preset_value="(loadedReport != null) ? getLoadedReportProperty('location') : undefined"
            :available_values="locationValues"
            required > <!-- TODO Set default preset value in settings -->
          </ReportEntryItem>
          <ReportEntryItem
            ref="reportControlExamination"
            lines="full"
            name="controlExamination"
            :display_name="i18n.$t('report.control_examination')"
            type="bool"
            :preset_value="(loadedReport != null) ? getLoadedReportProperty('control_examination') : undefined"
            required >
          </ReportEntryItem>

          <!-- Report Fields start here -->
          <div v-for="(category, categoryIndex) in reportTypeDefinition" 
            :key="categoryIndex" 
            :class="isCategoryVisible(category.name) ? 'category-list expanded-list' : 'category-list'" >
            <div class="category-header" v-if="category.name !== 'uncategorized'"> <!--TODO Add input for collapsed header to show invalidity or open the category on submit when invalid -->
              <ion-item lines="full" @click="toggleCategory(category.name)" button detail="false">
                <ion-icon slot="start" :icon="chevronForward"></ion-icon>
                <ion-label>
                  {{ getLocalizedReportString(category.name) }}
                </ion-label>
                <ion-badge slot="end" color="primary">{{ getPresetEntryCount(category.name) }}</ion-badge>
                <ion-badge slot="end" color="success">{{ getModifiedAndValidEntryCount(category.name) }}</ion-badge>
                <ion-badge slot="end" color="danger">{{ getInvalidEntryCount(category.name) }}</ion-badge>
              </ion-item>
            </div> <!-- TODO Keep in mind: Reports are immutable by design --> <!--TODO Test what happens, if decimal, text or number has no available values and no custom values allowed -->
            <ReportEntryItem v-for="(item, itemIndex) in category.items" :key="itemIndex"
              :ref="(el) => setReportEntryRef(el, category.name, itemIndex)"
              :category="category.name"
              :display_name="getLocalizedReportString(item.name)"
              :name="item.name"
              :type="item.type"
              :pro_only="item.pro_only"
              :allow_custom_values="item.allow_custom_values"
              :allow_multiple_values="item.allow_multiple_values"
              :unit="getLocalizedReportString(item.unit)"
              :available_values="getLocalizedReportStringArray(item.available_values)"
              :preset_value="(loadedReport != null) ? getLoadedReportField(category.name, item.name) : item.preset_value"
              :custom_placeholder="item.custom_placeholder"
              :required="item.required"
              :capture_options="item.capture_options" >
            </ReportEntryItem>
            <div v-for="(subCategory, subCategoryIndex) in category.sub_categories" :key="subCategoryIndex">
              <ion-item-divider>
                <ion-label>
                  {{ getLocalizedReportString(subCategory.name) }}
                </ion-label>
              </ion-item-divider>
              <ReportEntryItem v-for="(item, itemIndex) in subCategory.items" :key="itemIndex"
                :ref="(el) => setReportEntryRef(el, `${category.name}->${subCategory.name}`, itemIndex)"
                :category="category.name"
                :sub_category="subCategory.name"
                :display_name="getLocalizedReportString(item.name)"
                :name="item.name"
                :type="item.type"
                :pro_only="item.pro_only"
                :allow_custom_values="item.allow_custom_values"
                :allow_multiple_values="item.allow_multiple_values"
                :unit="getLocalizedReportString(item.unit)"
                :available_values="getLocalizedReportStringArray(item.available_values)"
                :preset_value="(loadedReport != null) ? getLoadedReportField(category.name, item.name, subCategory.name) : item.preset_value"
                :custom_placeholder="item.custom_placeholder"
                :required="item.required"
                :capture_options="item.capture_options" >
              </ReportEntryItem>
            </div>
            <!-- Additional input for fields we did not think of -->
            <div v-if="category.name !== 'uncategorized'" :class="isAdditionalCommentEnabled(category.name) ? 'expandable-additional-comments enabled' : 'expandable-additional-comments'">
              <ion-item-divider>
                <ion-label>
                  {{ i18n.$t('report.create.additional_comments') }}
                </ion-label>
                <ion-button class="add-comment-button" slot="end" shape="round" fill="clear" @click="toggleAdditionalComment(category.name)" @keydown="handleSpacebar($event, ()=>$event.target.click())">
                  <ion-icon slot="icon-only" :icon="addCircleOutline">
                  </ion-icon>
                </ion-button>
              </ion-item-divider>
              <ReportEntryItem
                lines="full"
                :ref="(el) => setReportEntryRef(el, `${category.name}->additional_comments`, 0)"
                :category="category.name"
                :display_name="i18n.$t('report.create.free_form_input')"
                name="additional_comments"
                type="text"
                :allow_custom_values="true"
                :preset_value="(loadedReport != null) ? getLoadedReportField(category.name, 'additional_comments') : undefined" >
              </ReportEntryItem> <!-- TODO Also count additional comments to the modified counters -->
            </div>
          </div>

          <!-- Additional input after all categories for additional_comments as an uncategorized item -->
          <div :class="isAdditionalCommentEnabled('uncategorized') ? 'expandable-additional-comments enabled' : 'expandable-additional-comments'">
            <ion-item-divider class="additional-comment-report-divider">
              <ion-label>
                {{ i18n.$t('report.create.additional_comments_report') }}
              </ion-label>
              <ion-button class="add-comment-button" slot="end" shape="round" fill="clear" @click="toggleAdditionalComment('uncategorized')" @keydown="handleSpacebar($event, ()=>$event.target.click())">
                <ion-icon slot="icon-only" :icon="addCircleOutline">
                </ion-icon>
              </ion-button>
            </ion-item-divider>
            <ReportEntryItem
              lines="full"
              :ref="(el) => setReportEntryRef(el, `uncategorized->additional_comments`, 0)"
              category="uncategorized"
              :display_name="i18n.$t('report.create.free_form_input')"
              name="additional_comments"
              type="text"
              :allow_custom_values="true"
              :preset_value="(loadedReport != null) ? getLoadedReportField('uncategorized', 'additional_comments') : undefined" >
            </ReportEntryItem>
          </div>
          <!-- Allows scrolling beyond the list to interact with items without the FAB blocking interaction -->
          <div id="over-scroll"></div>
        </ion-list>
      </form>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button :disabled="currentlySubmitting" ref="saveButton" color="success" @click="submitReport()" class="save-button"> <!-- TODO Disable until it is valid to send (Items have been added/modified) -->
          <ion-spinner v-if="currentlySubmitting"></ion-spinner>
          <ion-icon v-else :icon="saveOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
    <ion-progress-bar v-if="currentlySubmitting" color="success" class="upload-progress" :value="uploadProgress"></ion-progress-bar>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonLabel, IonList, IonItem, IonIcon, IonItemDivider, IonFab, IonFabButton, IonBadge, IonSelect, IonSelectOption, IonButton, IonSpinner, IonProgressBar, toastController } from '@ionic/vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { chevronForward, saveOutline, addCircleOutline } from 'ionicons/icons';

import MainToolbar from '@/components/MainToolbar.vue';
import ReportEntryItem from '@/components/ReportEntryItem.vue';
import CreateHorsePopup from '@/components/CreateHorsePopup.vue';
import { openFilterPopupAsSelectInterface, default as filterPopupComponent } from '@/components/FilterPopup.vue';

import _ from 'lodash';

import { useI18n } from "@/utils/i18n";

import { useDayjs } from '@/utils/dayjs';

import { apiErrorToast } from '@/utils/error';
//TODO When multiple languages get supported, check that the untranslated version is always sent!
export default  {
  name: 'CreateReport',
  components: { IonHeader, IonContent, IonPage, MainToolbar, IonLabel, IonList, IonItem, IonIcon, IonItemDivider, IonFab, IonFabButton, IonBadge, IonSelect, IonSelectOption, IonButton, IonSpinner, IonProgressBar, ReportEntryItem, CreateHorsePopup },
  props: {
    typeId: String,
    id: String
  },
  setup(props){
    const i18n = useI18n();

    const locationValues = computed(() => _.map(store.getters['reports/getLocationValues'], (value) => {
      return { value, display: i18n.$t(`report.location.${value}`) };
    }));

    const store = useStore();
    
    const router = useRouter();

    const { dayjs, dayjsLocale, isReady, timezone } = useDayjs();

    const formRef = ref(null);

    const saveButton = ref(null);

    const reportDateTime = ref(null);

    const reportLocation = ref(null);

    const reportControlExamination = ref(null);

    const reportEntryRefs = ref({});

    const loadingReport = ref(false);

    const loadingReportType = ref(false);

    const loading = computed(() => (loadingReport.value || loadingReportType.value));

    const loadedReport = ref(null); //loadedReport is the indication, if we are in edit mode!

    const reportTypeId = computed(() => {
      if (props.typeId) {
        return props.typeId;
      } else if (loadedReport.value != null && loadedReport.value.type) {
        return loadedReport.value.type;
      } else {
        return null;
      }
    });

    const enabledAdditionalComments = ref({});

    //Set the additional_comments to be visible when they have a value
    watch(loadedReport, (newReport) => {
      if (newReport && newReport.fields) {
        for (let [categoryName, category] of Object.entries(newReport.fields)) {
          if (category['additional_comments'] != null) {
            enabledAdditionalComments.value[categoryName] = true;
          }
        }
      }
    });

    const now = dayjs.utc().toISOString();

    const tomorrow = dayjs.utc().add(1, 'day').toISOString();

    const reportType = ref(null);

    const visibleCategories = ref({'uncategorized': true}); //Uncategorized is always visible by default

    const selectHorseInput = ref(null);

    const createHorsePopup = ref(null);

    const currentlySubmitting = ref(false);

    const uploadProgress = ref(0);

    const reportTypeDefinition = computed(() => {
      if (reportType.value) {
        return reportType.value.definition; //TODO Maybe only fetch if not already loaded and exclude normally when fetching all types, only return on single type request
      } else {
        return null;
      }
    });

    const localizedReportTypeName = computed(() => {
      if (reportType.value) {
        return reportType.value.descriptor; //TODO Use the translated name here
      } else {
        return null;
      }
    });

    const availableHorses = computed(() => store.getters['horses/getHorses']);

    const loadedSelectedHorse = ref(null);

    //Stores the selection either temporarily for editing or in store if we create a new one
    const selectedHorseModel = computed({
      get: () => {
        //If we edit a report, the horse is set there, else use the currently selected one in store
        if (loadedReport.value != null) {
          return loadedSelectedHorse.value;
        } else {
          return store.getters['horses/getSelectedHorse'];
        }
      },
      set: (newHorse) => {
        if (loadedReport.value != null) {
          loadedSelectedHorse.value = newHorse;
        } else {
          store.dispatch('horses/updateSelectedHorse', newHorse);
        }
      }
    })

    const selectedHorse = computed({
      get: () => {
        let horseId = selectedHorseModel.value;

        if (horseId != null) {
          return horseId.toString();
        } else {
          return null;
        }
      },
      set: (newHorse) => {
        if (newHorse !== 'new'){
          selectedHorseModel.value = parseInt(newHorse);
        } else {
          let previouslySelectedHorse = selectedHorseModel.value;
          
          createHorsePopup.value.show().then((horse) => {
            selectedHorseModel.value = horse;
          })
          .catch(() => {
            selectedHorseModel.value = undefined; //Set temporarily to undefined to refresh the select input
            selectedHorseModel.value = previouslySelectedHorse;
          });
          
        }
      }
    });

    const openCustomSelectInterface = function(event) {
      openFilterPopupAsSelectInterface(filterPopupComponent, event);
    }

    //Returns the property, if it is set and the loadedReport exists, else undefined
    const getLoadedReportProperty = computed(() => (propertyName) => {
      if (loadedReport.value != null && loadedReport.value[propertyName] != null) {
        return loadedReport.value[propertyName];
      }
      return undefined;
    });

    //Returns the field value, if it exists, else undefined
    const getLoadedReportField = computed(() => (category, name, subCategory = null) => {
      if (category && loadedReport.value.fields && loadedReport.value.fields[category]){
        if (subCategory){
          if (loadedReport.value.fields[category][subCategory] && loadedReport.value.fields[category][subCategory][name]){
            let item = loadedReport.value.fields[category][subCategory][name];
            //Return the first find of any possible contents
            if (item.value != null) return item.value;
            if (item.file != null) return item.file;
            if (item.files != null) return item.files;
          }
        } else {
          if (loadedReport.value.fields[category][name]){
            let item = loadedReport.value.fields[category][name];
            //Return the first find of any possible contents
            if (item.value != null) return item.value;
            if (item.file != null) return item.file;
            if (item.files != null) return item.files;
          }
        }
      }
      return undefined;
    });

    const getLocalizedReportString = function(text) { //TODO Move these and the ones in ViewReport maybe to vuex store
      return text; //TODO Use translation relation to translate each string here.
    }

    const getLocalizedReportStringArray = function(textArray) {
      if (Array.isArray(textArray)){
        return textArray.map((text) => {
          return {
            value: text,
            display: getLocalizedReportString(text)
          }
        });
      } else {
        return null;
      }
    }

    const setReportEntryRef = function(el, categoryName, itemIndex) {
      if (el && categoryName) {
        if (!reportEntryRefs.value[categoryName]){
          reportEntryRefs.value[categoryName] = [];
        }
        reportEntryRefs.value[categoryName][itemIndex] = el;
      }
    }

    const isCategoryVisible = function(categoryName) {
      return (categoryName in visibleCategories.value && visibleCategories.value[categoryName] === true);
    }

    const toggleCategory = function(categoryName) {
      if (categoryName in visibleCategories.value) {
        if (visibleCategories.value[categoryName] === true) {
          visibleCategories.value[categoryName] = false;
        } else {
          visibleCategories.value[categoryName] = true;
        }
      } else {
        visibleCategories.value[categoryName] = true;
      }
    }
    
    const handleSpacebar = function(event, handler){
      if (event.key === ' ') {
        event.preventDefault();
        handler();
      }
    }

    const toggleAdditionalComment = function(categoryName) {
      if (categoryName in enabledAdditionalComments.value) {
        if (enabledAdditionalComments.value[categoryName] === true) {
          enabledAdditionalComments.value[categoryName] = false;
        } else {
          enabledAdditionalComments.value[categoryName] = true;
        }
      } else {
        enabledAdditionalComments.value[categoryName] = true;
      }
    }

    const isAdditionalCommentEnabled = function(categoryName) {
      return (categoryName in enabledAdditionalComments.value && enabledAdditionalComments.value[categoryName] === true);
    }

    const getPresetEntryCount = function(categoryName) {
      let presetEntryCount = 0;
      if (reportEntryRefs.value[categoryName]) {
        for (let reportEntry of reportEntryRefs.value[categoryName]) {
          if (reportEntry.hasValidPreset && !reportEntry.isModified) presetEntryCount++;
        }
      }

      if(presetEntryCount === 0) return null;
      else return presetEntryCount;
    }

    const getModifiedAndValidEntryCount = function(categoryName) {
      let modifiedEntryCount = 0;
      if (reportEntryRefs.value[categoryName]) {
        for (let reportEntry of reportEntryRefs.value[categoryName]) {
          if (reportEntry.isModified && !reportEntry.isInvalid) modifiedEntryCount++;
        }
      }

      if(modifiedEntryCount === 0) return null;
      else return modifiedEntryCount;
    }

    const getInvalidEntryCount = function(categoryName) {
      let invalidEntryCount = 0;
      if (reportEntryRefs.value[categoryName]) {
        for (let reportEntry of reportEntryRefs.value[categoryName]) {
          if (reportEntry.isInvalid) invalidEntryCount++;
        }
      }

      if(invalidEntryCount === 0) return null;
      else return invalidEntryCount;
    }

    const getLocalTimeObject = computed(() => {
      return (timestamp) => {
        if (isReady.value) {
          return dayjs.utc(timestamp).locale(dayjsLocale.value).tz(timezone);
        } else {
          return dayjs.utc(timestamp).tz(timezone);
        }
      }
    });

    const submitReport = function() {
      //Validate form with constraints that are set inside the entry items and show the result with reportValidity (returns result of checkValidity)
      if (formRef.value.reportValidity()){

        currentlySubmitting.value = true;
        uploadProgress.value = 0;

        let report = {
          timestamp: reportDateTime.value.getRawValue(),
          type: parseInt(reportTypeId.value),
          location: reportLocation.value.getRawValue(),
          control_examination: reportControlExamination.value.getRawValue()
        };

        //Add horse now, if it is selected
        if (selectedHorse.value){
          report.horse = parseInt(selectedHorse.value, 10);
        }

        //Save the basic details separately for upload cache
        let reportDetails = _.cloneDeep(report);

        //Add empty array for fields at the end of the object
        report.fields = [];

        let formData = new FormData();

        let totalFileSize = 0;

        //Query each ReportEntryItem for its values and add them to the report
        for (let reportEntries of Object.values(reportEntryRefs.value)) {
          for (let reportEntry of reportEntries) {
            if (loadedReport.value == null || reportEntry.isModified){ //If we edit a loadedReport, then only send changed fields! //TODO Check how it behaves when a file is unmodified and then if it is modified //TODO Also check when multiple files are allowed when we have an unmodified and a modified one 
              let entryComponents = reportEntry.getEntryComponents();
              if (entryComponents.empty) {
                if (loadedReport.value != null) { //Add empty component to unset it on the server only if in editing mode
                  report.fields.push(entryComponents.component);
                }
              } else {
                let nextIndex = report.fields.push(entryComponents.component);

                //If files are added, add them to a separate part of the FormData with the index of the component inside the report. 
                //If multiple files are uploaded, multiple appends wit the same name key are performed
                if (entryComponents.files != null) {
                  let currentKey = `fields[${nextIndex - 1}].file`
                  
                  for (let file of entryComponents.files) {
                    report.fields[nextIndex - 1].uploaded_at = Date.now(); //TODO Move this to the server
                    formData.append(`files.${currentKey}`, file, file.name);
                    totalFileSize += file.size;
                  }
                }
              }
            }
          }
        }

        if (report.fields.length > 0 || loadedReport.value != null){ //Also send if it is in edit mode, because some attributes might have changed without any fields //TODO Check that at least something has changed, before sending!
          formData.append('data', JSON.stringify(report));

          const progressCallback = (progress) => uploadProgress.value = progress;

          let submitPromise;

          if (loadedReport.value != null){
            submitPromise = store.dispatch('reports/updateReport', {id: props.id, reportFormData: formData, totalFileSize, progressCallback, reportDetails});
          } else {
            submitPromise = store.dispatch('reports/createNewReport', {reportFormData: formData, totalFileSize, progressCallback, reportDetails});
          }

          submitPromise
          .then(({index, persistent}) => {
            //If not persistent, show a warning to the user to not close the app
            if (!persistent) {
              return toastController.create(
                {
                  message: i18n.$t('report.create.upload_queue_not_persistent'),
                  position: 'top',
                  duration: 0,
                  color: 'warning',
                  buttons: [
                    {
                      text: i18n.$t('default_interaction.close'),
                      role: 'cancel'
                    }
                  ]
                }
              ).then((toast) => {
                toast.present();
                return index;
              })
              .catch(() => {
                return index;
              });
            } else {
              return Promise.resolve(index);
            }
          })
          .then((index) => {
            //Go to uploading report page or tracking page if index is null
            if (index != null) {
              router.replace(`/health/analysis/report/U${index}`);
            } else {
              router.replace('/health/tracking');
            }
          })
          .catch(() => {
            //Go to tracking page
            router.replace('/health/tracking');
          })
          .finally(() => {
            currentlySubmitting.value = false;
            uploadProgress.value = 0;
            //Set the currently selected day and month to the one of this report, to immediately scroll to the upload to see progress
            let localReportTime = getLocalTimeObject.value(reportDetails.timestamp);
            store.dispatch('reports/updateSelectedMonth', localReportTime.format('MM.YYYY'));
            store.dispatch('reports/updateSelectedDay', localReportTime.format('DD.MM.YYYY'));
            store.dispatch('reports/updateSelectedTimespanDay', null);
            //TODO Reset filters to show the currently uploading one!!!
          });
        } else {
          currentlySubmitting.value = false;
          uploadProgress.value = 0;
        }
      }
    }
    
    const fetchReport = function(id) {
      if (id != null && loadedReport.value == null){ //Load report only if none has been supplied
        loadingReport.value = true;
        store.dispatch('reports/fetchReport', id)
        .then((loadedReportObject) => {
          loadedReport.value = loadedReportObject;
          if (loadedReport.value != null && loadedReport.value.horse != null) {
            loadedSelectedHorse.value = loadedReport.value.horse;
          }
        })
        .then(() => {
          loadingReport.value = false;
        })
        .catch((error) => {
          apiErrorToast(i18n, error)
            .then(() => router.go(-1));
        });
      }  
    }

    watch(() => props.id, (newId) => fetchReport(newId));

    const fetchReportType = function(typeId){
      if (typeId != null && reportType.value == null) {
        loadingReportType.value = true;
        store.dispatch('reports/fetchReportType', typeId)
        .then((loadedReportType) => {
          reportType.value = loadedReportType;
        })
        .then(() => {
          loadingReportType.value = false;
        })
        .catch((error) => {
          apiErrorToast(i18n, error)
            .then(() => router.go(-1));
        });
      }
    }

    //Fetch the report type definition, as soon as we know which one to load
    watch(reportTypeId, (newTypeId) => fetchReportType(newTypeId));

    onMounted(() => {
      if (props.id) {
        fetchReport(props.id);
      }
      if (reportTypeId.value) {
        fetchReportType(reportTypeId.value)
      }
    }); //FIXME Slow loading time. Maybe onMount of all ReportEntryItems?

    return { 
      i18n, 
      now,
      tomorrow,
      locationValues,
      formRef,
      saveButton,
      reportDateTime,
      reportLocation,
      reportControlExamination,
      reportType, 
      reportTypeDefinition,
      loadedReport, 
      loading,
      getLoadedReportProperty,
      getLoadedReportField,
      localizedReportTypeName,
      availableHorses,
      openCustomSelectInterface,
      selectedHorse,
      selectHorseInput,
      createHorsePopup,
      currentlySubmitting,
      uploadProgress,
      getLocalizedReportString, 
      getLocalizedReportStringArray,
      reportEntryRefs,
      setReportEntryRef,
      isCategoryVisible, 
      toggleCategory,
      handleSpacebar,
      toggleAdditionalComment,
      isAdditionalCommentEnabled,
      getPresetEntryCount,
      getModifiedAndValidEntryCount,
      getInvalidEntryCount,
      submitReport,
      chevronForward,
      saveOutline,
      addCircleOutline
    };
  }
}
</script>

<style scoped>
#report-title {
  width: 100%;
  display: block;
  text-align: center;
  padding: 10px;
  font-size: 1.5em;
  background-color: var(--ion-color-tertiary-shade);
  color: var(--ion-color-tertiary-contrast);
}

#over-scroll {
  width: 100%;
  height: 100px;
}

.category-header {
  cursor: pointer;
}

/* Animate Rotation of chevron icon */
.category-header ion-icon {
  transition: transform 0.2s ease-in-out;
}
.expanded-list .category-header ion-icon {
  transform: rotate(90deg);
}

/* Collapse items */
.category-list:not(.expanded-list) > :not(div),
.category-list:not(.expanded-list) > div:not(.category-header) > * {
  display: none;
}

.category-header > * {
  --color: var(--ion-color-secondary-shade);
  font-weight: bold;
}

.category-header ion-icon {
  color: var(--ion-color-secondary-shade);
}

.category-header ion-label {
  white-space: normal;
}

ion-badge {
  margin-left: 10px;
}

ion-item-divider {
  min-height: 40px;
}

.additional-comment-report-divider {
  --background: var(--ion-color-secondary-shade);
  --color: var(--ion-color-secondary-contrast);
}
.additional-comment-report-divider > ion-button {
  --color: var(--ion-color-secondary-contrast);
  --background-activated: var(--ion-color-secondary-contrast);
  --background-focused: var(--ion-color-secondary-contrast);
  --background-hover: var(--ion-color-secondary-contrast);
}

.add-comment-button {
  --background-focused-opacity: 0.25  ;
}

.add-comment-button:focus {
  outline: none;
}

/* Hide non-enabled additional-comments */
.expandable-additional-comments:not(.enabled) ion-item {
  display: none;
}

/* Hide enable button, when it is enabled */
.expandable-additional-comments.enabled > ion-item-divider > ion-button {
  display: none;
}

.upload-progress {
  position: absolute;
  bottom: 0px;
  z-index: 9000;
  height: 6px;
}

.save-button {
  opacity: 1!important;
}

.save-button ion-spinner {
  color: var(--ion-color-contrast);
}
</style>
